<template>
    <div class="block">
        <el-cascader
                ref="cascaderHandle"
                style="width: 100%"
                placeholder="搜索"
                :options="options"
                @change="onSearch"
                clearable
                filterable>
        </el-cascader>
    </div>
</template>

<script>
import { getAllSiteList } from '../../../api'

export default {
  name: 'cascader',
  data () {
    return {
      options: [
        {
          value: '0',
          label: '节制闸',
          children: []
        },
        // {
        //   value: '1',
        //   label: '雨情',
        //   children: []
        // },
        {
          value: '1',
          label: '测流点',
          children: []
        },
        // {
        //   value: '3',
        //   label: '渠道流量计',
        //   children: []
        // },
        {
          value: '2',
          label: '泵站',
          children: []
        }
      ],
      siteVos: [],
      rainVos: [],
      flowMeters: [],
      ditchFlowMeters: [],
      pumpStations: []
    }
  },
  // props: {
  //   siteVos: {
  //     type: Array,
  //     default () {
  //       return []
  //     }
  //   },
  //   rainVos: {
  //     type: Array,
  //     default () {
  //       return []
  //     }
  //   },
  //   flowMeters: {
  //     type: Array,
  //     default () {
  //       return []
  //     }
  //   },
  //   ditchFlowMeters: {
  //     type: Array,
  //     default () {
  //       return []
  //     }
  //   },
  //   pumpStations: {
  //     type: Array,
  //     default () {
  //       return []
  //     }
  //   }
  // },
  mounted () {
    // setTimeout(() => {
    //   console.log(this.siteVos)
    this.getOptions()
    // }, 1000)
  },
  methods: {
    getOptions () {
      getAllSiteList().then(res => {
        this.siteVos = res.data.siteVos
        this.rainVos = res.data.rainVos
        this.ditchFlowMeters = res.data.ditchFlowMeters.map(item => { return { ...item, lng: item.lon } })
        this.flowMeters = res.data.flowMeters.map(item => { return { ...item, lng: item.lon } })
        this.pumpStations = res.data.pumpStations.map(item => { return { ...item, lng: item.lon } })

        console.log(this.pumpStations)

        this.options[0].children = this.siteVos.map(item => { return { value: item.siteName || '', label: item.siteName || '', lat: item.lat || '0', lng: item.lng || '0' } })
       // this.options[1].children = this.rainVos.map(item => { return { value: item.rainName || '', label: item.rainName || '', lat: item.lat || '0', lng: item.lng || '0' } })
        this.options[1].children = this.flowMeters.map(item => { return { value: item.name || '', label: item.name || '', lat: item.lat || '0', lng: item.lon || '0' } })
        let list = this.ditchFlowMeters.map(item => { return { value: item.meterName || '', label: item.meterName || '', lat: item.lat || '0', lng: item.lon || '0' } })
        this.options[1].children=this.options[1].children.concat(list)
        console.log(this.options[1]);
        this.options[2].children = this.pumpStations.map(item => { return { value: item.staName || '', label: item.staName || '', lat: item.lat || '0', lng: item.lon || '0' } })
      })
    },
    onSearch (val) {
      if (this.$refs.cascaderHandle.checkedValue.length === 0) {
        this.$emit('data', '')
      } else {
        const value01 = val[1]
        console.log(this.options[val[0]].children, value01)
        const item = this.options[val[0]].children.filter(item => item.label.indexOf(value01) !== -1)
        const data = item[0]
        this.$emit('data', data)
      }
    }
  }
}
</script>
<style>
    .el-scrollbar__wrap{
        margin-bottom: 0px !important;
    }
</style>
<style lang="scss" scoped>

.block{

}
</style>
